<template lang="html">
    <div class="container main">
        <top-navigation-bar
            title="Regression"
            right-nav="reset"
            @click="doReset"
        />

        <div class="text-left divider">
            <h2>R = correlation</h2>
            <h2>r = correlation estimate</h2>
        </div>

        <div class="m-view divider">
            <h2>ŷ = {{ allData.regrEquation[1] }}{{ allData.eqSign }}{{ allData.regrEquation[0] }}x</h2>
            <h2>r ={{ allData.dataHT.r }}</h2>
        </div>

        <div class="m-view m-align">
            <h2 class="me-3">
                H<sub>0</sub> :  R  =  0
            </h2>
        </div>
        <div class="m-view d-flex">
            <h2>H<sub>A</sub> :  R </h2>
            <select
                v-model="selected.value"
                class="form-select mx-2"
            >
                <option
                    v-for="(option, index) in options"
                    :key="index"
                    :value="option.value"
                >
                    {{ option.value }}
                </option>
            </select>
            <h2> 0</h2>
        </div>

        <div class="d-flex justify-content-around mt-4">
            <h3 class="me-3">
                t<sup>*</sup>= {{ allData.dataHT.tR }}
            </h3>
            <h3>df = n - 2 = {{ allData.df }}</h3>
        </div>

        <div class="text-center m-2 pb-2">
            <h3>p-value = {{ pValue.output }}</h3>
        </div>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import localStore from '@/store/localStore.js'
var jStat = require('jstat').jStat

export default {
    name: 'Rht',
    components: {
        TopNavigationBar,
    },
    data () {
        return {
            allData: [],
            values: '',
            options: [
                { value: '>' },
                { value: '≠' },
                { value: '<' },
            ],
            selected: { value: '≠' }
        }
    },
    computed: {
        pValue () {
            let output
            if (this.selected.value === '>') {
                output = this.values.pValueGreater
            }
            if (this.selected.value === '≠') {
                output = this.values.pValueNotEqual
            }
            if (this.selected.value === '<') {
                output = this.values.pValueLess
            }
            return { output, percent: output * 100 }
        }
    },
    beforeMount () {
        var data = localStore.getStore('regression-data')
        this.allData = data

        var p = jStat.studentt.cdf(data.dataHT.tR, data.df)
        var pValueLess = Number(p.toFixed(4)) // >
        var pValueGreater = 1 - pValueLess // >
        pValueGreater = Number(pValueGreater.toFixed(4))
        var pValueNotEqual

        if (pValueLess < pValueGreater) {
            pValueNotEqual = 2 * pValueLess
        } else {
            pValueNotEqual = pValueGreater * 2 // ≠
        }

        this.values = { pValueGreater, pValueNotEqual, pValueLess }
    },
    methods: {
        doReset () {
            this.selected.value = '≠'
        },
    }
}
</script>
